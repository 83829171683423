import { DialogModule, DialogRef } from "@angular/cdk/dialog";

import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoModule } from "@ngneat/transloco";
import { TuiButtonModule } from "@taiga-ui/core";
import { TranslocoMarkupComponent } from "ngx-transloco-markup";
import { environment } from "../../../../environments/environment";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "dev-portal-cookie-dialog",

  imports: [DialogModule, TuiButtonModule, TranslocoModule, TranslocoMarkupComponent],
  templateUrl: "./cookie-dialog.component.html",
  styles: [
    `
      :host {
        @apply tablet:right-8 tablet:left-auto tablet:bottom-8 tablet:w-[20rem] absolute bottom-0 left-0 right-0;
      }
    `
  ]
})
export class CookieDialogComponent {
  legalUrls = environment.legal;

  constructor(public dialogRef: DialogRef<boolean>) {}

  accept() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
