import * as schema from "@libs/shares/gateway/config.schema.json";
import { NgxMonacoEditorConfig } from "ngx-monaco-editor-v2";

export function onMonacoLoad() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (<any>window).monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemas: [
      {
        uri: "http://example.com/schema-name.json", // id of the first schema
        fileMatch: ["*"], // associate with our model
        schema: {
          ...schema.definitions.overrideEntity,
          definitions: schema.definitions
        }
      }
    ]
  });
}

export const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: window.location.origin + "/assets/monaco/min/vs",
  defaultOptions: {
    scrollBeyondLastLine: false,
    automaticLayout: true,
    formatOnPaste: true,
    quickSuggestions: {
      other: false,
      comments: false,
      strings: true
    },
    theme: "vs-dark"
  },
  onMonacoLoad
};
