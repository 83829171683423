import { Injectable, InjectionToken, PLATFORM_ID, effect, inject } from "@angular/core";
import { ThemeSwitcherService } from "../../feature/theme-switcher.service";
import { isPlatformBrowser } from "@angular/common";
import {
  NetworkChainId,
  WalletAddress,
  getDevPortalWalletAddress,
  getTokenAddresses
} from "@libs/shares/models/wallet.model";
import { UnleashToggleService } from "../unleash/unlease.service";
import { map } from "rxjs";
import { createSignal } from "ngxtension/create-signal";
import { toSignal } from "@angular/core/rxjs-interop";
import { computedAsync } from "ngxtension/computed-async";
import { type AppKit } from "@reown/appkit";
import { type Config } from "@wagmi/core";

export const WALLET_CONNECT_PROJECT_ID = new InjectionToken<string>("__WALLET_CONNECT_PROJECT_ID__");

export const ECR20_ABI = [
  {
    type: "function",
    name: "balanceOf",
    stateMutability: "view",
    inputs: [{ name: "owner", type: "address" }],
    outputs: [{ type: "uint256" }]
  },
  {
    type: "function",
    name: "transfer",
    inputs: [
      { name: "to", type: "address" },
      { name: "value", type: "uint256" }
    ],
    outputs: [{ type: "bool" }]
  }
] as const;

@Injectable({
  providedIn: "root"
})
export class WalletConnectService {
  private themeService = inject(ThemeSwitcherService);
  private walletConnectProjectId = inject(WALLET_CONNECT_PROJECT_ID);
  private unleashService = inject(UnleashToggleService);
  private modal = createSignal<AppKit | null>(null);
  private wagmiConfig = createSignal<Config | null>(null);
  private unwatchAccount!: () => void;

  activeChainId = toSignal(
    this.unleashService
      .getVariant$("paymentNetwork")
      .pipe(
        map((variant) => (variant.payload?.value ? (parseInt(variant.payload?.value) as NetworkChainId) : undefined))
      )
  );
  isConnected = createSignal(false);
  address = createSignal<WalletAddress | undefined>(undefined);
  chainId = createSignal<number | undefined>(undefined);
  walletIcon = createSignal<string | null>(null);
  balances = computedAsync<{
    usdc: number;
    ethereum: number;
  } | null>(
    async () => {
      const activeChainId = this.activeChainId();
      const walletChainId = this.chainId();
      const address = this.address();
      const isConnected = this.isConnected();
      const wagmiConfig = this.wagmiConfig();
      if (activeChainId && address && activeChainId === walletChainId && isConnected && wagmiConfig) {
        const { getBalance } = await import("@wagmi/core");
        const { formatUnits } = await import("viem");

        try {
          const balanceUsdc = await getBalance(wagmiConfig, {
            address,
            token: getTokenAddresses(this.activeChainId()).USDC
          });
          const balanceEther = await getBalance(wagmiConfig, {
            address
          });

          return {
            usdc: parseFloat(formatUnits(balanceUsdc.value, 6)),
            ethereum: parseFloat(formatUnits(balanceEther.value, 18))
          };
        } catch (error) {
          console.log(error);
          return null;
        }
      }
      return null;
    },
    { initialValue: null }
  );

  constructor() {
    if (isPlatformBrowser(inject(PLATFORM_ID))) {
      effect(async () => {
        const activeChainId = this.activeChainId();
        if (activeChainId) {
          const modal = await this.createModal(this.walletConnectProjectId);
          this.modal.set(modal);

          modal.subscribeCaipNetworkChange((chainId) => {
            if (chainId && chainId.id) {
              this.chainId.set(chainId.id as number);
            }
          });

          modal.subscribeWalletInfo((info) => {
            if (info && info.icon) {
              this.walletIcon.set(info.icon);
            }
          });
        }
      });

      effect(() => {
        this.modal()?.setThemeMode(this.themeService.currentTheme() === "onDark" ? "dark" : "light");
      });
    }
  }

  open() {
    this.modal()?.open();
  }

  async disconnect() {
    const { disconnect } = await import("@wagmi/core");
    await disconnect(this.wagmiConfig()!);
    this.modal()?.resetWcConnection();
    this.modal()?.resetNetwork("eip155");
    this.modal()?.resetAccount("eip155");
    this.unwatchAccount();
  }

  /**
   * Calculate the gas unit for the given USDC amount via RPC node.
   *
   * @param usdcAmount e.g. 499.00 USDC
   * @returns gas unit as bigint
   */
  async estimateGasUnits(usdcAmount: number) {
    const { estimateGas } = await import("@wagmi/core");
    const { parseUnits } = await import("viem");

    // we calculate how much the transfer function on the smart contract would cost in gas units
    try {
      const gasUnits = await estimateGas(this.wagmiConfig()!, {
        to: getDevPortalWalletAddress(this.activeChainId()),
        value: parseUnits((Math.round(usdcAmount * 100) / 100).toString(), 6)
      });

      return gasUnits;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.cause?.name === "InsufficientFundsError") {
        console.error("transfer amount exceeds balance");
      }
    }

    return null;
  }

  async getGasPrice() {
    return await (await import("@wagmi/core")).getGasPrice(this.wagmiConfig()!);
  }

  async sendTransaction(usdcAmount: number, gasLimit: bigint, maxPriorityFeePerGas: bigint, maxFeePerGas: bigint) {
    const { parseUnits, encodeFunctionData } = await import("viem");
    const { sendTransaction } = await import("@wagmi/core");

    const data = encodeFunctionData({
      abi: ECR20_ABI,
      args: [
        getDevPortalWalletAddress(this.activeChainId()),
        parseUnits((Math.round(usdcAmount * 100) / 100).toString(), 6)
      ],
      functionName: "transfer"
    });

    const tx = await sendTransaction(this.wagmiConfig()!, {
      to: getTokenAddresses(this.activeChainId()).USDC,
      data,
      maxFeePerGas: maxFeePerGas,
      maxPriorityFeePerGas: maxPriorityFeePerGas
    });

    return tx;
  }

  async switchNetwork(chainId: NetworkChainId) {
    try {
      const networks = this.modal()!.getCaipNetworks("eip155");
      const chain = networks.find((network) => network.id === chainId)!;
      await this.modal()?.switchNetwork(chain);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message === "Missing or invalid. request() method: wallet_switchEthereumChain") {
        throw new Error("SWITCH_NETWORK_NOT_SUPPORTED_BY_WALLET");
      }
      throw error;
    }
  }

  private async createModal(projectId: string): Promise<AppKit> {
    const sitepath = `${window.location.protocol}//${window.location.host}`;
    const metadata = {
      name: document.title,
      description: document.querySelector('meta[name="description"]')?.attributes.getNamedItem("content")?.value ?? "",
      url: sitepath,
      icons: [`${sitepath}/assets/logo-small.svg`]
    };

    const { createAppKit } = await import("@reown/appkit");
    const { mainnet, sepolia } = await import("viem/chains");
    const { watchAccount, watchChainId, createStorage, cookieStorage, reconnect } = await import("@wagmi/core");
    const { WagmiAdapter } = await import("@reown/appkit-adapter-wagmi");
    const wagmiAdapter = new WagmiAdapter({
      projectId,
      networks: [mainnet, sepolia],
      storage: createStorage({
        storage: cookieStorage
      }),
      syncConnectedChain: true
    });

    this.wagmiConfig.set(wagmiAdapter.wagmiConfig);

    this.unwatchAccount = watchAccount(wagmiAdapter.wagmiConfig, {
      onChange: ({ address, isConnected }) => {
        this.isConnected.set(isConnected);
        this.address.set(address as WalletAddress);
      }
    });

    watchChainId(wagmiAdapter.wagmiConfig, {
      onChange: (chainId) => {
        this.chainId.set(chainId);
      }
    });

    const modal = createAppKit({
      adapters: [wagmiAdapter],
      networks: [mainnet, ...(this.activeChainId() === NetworkChainId.Sepolia ? [sepolia] : [])],
      tokens: {
        [`eip155:${NetworkChainId.EthereumMainnet}`]: {
          address: getTokenAddresses(NetworkChainId.EthereumMainnet).USDC
        },
        [`eip155:${NetworkChainId.Sepolia}`]: {
          address: getTokenAddresses(NetworkChainId.Sepolia).USDC
        }
      },
      allowUnsupportedChain: false,
      metadata,
      projectId,
      features: {
        swaps: false,
        onramp: false,
        socials: [],
        email: false
      }
    });

    reconnect(wagmiAdapter.wagmiConfig);

    return modal;
  }
}
