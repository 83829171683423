import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SharedGuardService } from "./shared-guard.service";

export const isPaymentOwnerGuard: CanActivateFn = async (route) => {
  const router = inject(Router);
  const sharedGuardService = inject(SharedGuardService);

  // Check if payment is associated with the current organization
  const payments = sharedGuardService.getSubscriptionPayments();

  const belongingPayment = payments?.find((payment) => payment.id === route.params["paymentId"]);

  if (!belongingPayment) {
    return router.parseUrl("/dashboard");
  }

  return true;
};
