// auth.service.ts
import { inject, Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { ProfileService } from "../profile/profile.service";
import { CrmService } from "../crm/crm.service";
import { filter, firstValueFrom, map } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { SurveyService } from "../survey/survey.service";
import { SubscriptionService } from "../subscription/subscription.service";

@Injectable({
  providedIn: "root"
})
export class SharedGuardService {
  private authService = inject(AuthService);
  private profileService = inject(ProfileService);
  private subscriptionService = inject(SubscriptionService);
  private crmService = inject(CrmService);

  user = this.authService.getUser();
  surveyService = inject(SurveyService);

  organization = this.profileService.organization$.pipe(
    takeUntilDestroyed(),
    filter((organizations) => Array.isArray(organizations) && organizations.length > 0),
    map((organizations) => organizations![0])
  );

  requiredSurveyList = this.surveyService.requiredSurveysForLoggedInUser$.pipe(
    takeUntilDestroyed(),
    filter((surveys) => surveys !== undefined)
  );

  async hasUser() {
    return firstValueFrom(this.user);
  }

  async requiresVerification() {
    const isVerified = await firstValueFrom(
      this.crmService.isVerified$.pipe(filter((isVerified) => isVerified !== undefined))
    );
    const orgPromoCodes = (await firstValueFrom(this.organization)).orgPromoCodes?.find(
      (promoCode) => promoCode.entityVerificationsRequired === false
    );

    return orgPromoCodes?.entityVerificationsRequired === false ? false : isVerified === false ? true : false;
  }

  getSubscriptionPayments() {
    const activeSubscription = this.subscriptionService.activeSubscription();
    return activeSubscription?.payments;
  }

  async isSetupCompleted() {
    return (await firstValueFrom(this.user))?.setupCompleted;
  }
}
